import { useState, useCallback, useEffect, Suspense, lazy } from "react";
import './App.scss';
import Home from './screens/Home';
import Loader from "./compontents/Spinner/index";
import 'aos/dist/aos.css';
import AOS from 'aos';
function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay before setting loading to false
    const delay = setTimeout(() => {
      setLoading(false);
    }, 5000); // 2000 milliseconds delay (adjust as needed)

    return () => clearTimeout(delay); // Clean up timeout on unmount
  }, []);
  AOS.init({
    duration: 1000
  });
  return (
    <>
        <Suspense fallback={<Loader />}>
     {loading ? <Loader /> :
              <Home/>
            }

       </Suspense>
      {/* <Home/> */}
      {/* <Footer /> */}
    </>
  );
}

export default App;
