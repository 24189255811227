import React , { useState, useEffect } from 'react';
import Banner from "../compontents/banner";
function Home() {
  const [scrollingLock, setScrollingLock] = useState(false);
  const [activeLink, setActiveLink] = useState('');
  useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      const url = window.location.href;
      const handleHashChange = () => {
        const fragment = window.location.hash;
        setActiveLink(fragment);
    };

    // Set active link on initial load
    handleHashChange();

    // Listen for hash changes
    window.addEventListener('hashchange', handleHashChange);

    // Cleanup function
    return () => {
        window.removeEventListener('hashchange', handleHashChange);
    };
  })

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrollingLock(true);
    } else if (window.scrollY < 0) {
      setScrollingLock(false);
    }
  }
  document.addEventListener("DOMContentLoaded", function(){
    window.addEventListener('scroll', function() {
      if (window.scrollY > 50) {
        document.getElementById('navbar_top').classList.add('fixed-top');
        // add padding top to show content behind navbar
        var navbar_height = document.querySelector('.navbar').offsetHeight;
        document.body.style.paddingTop = navbar_height + 'px';
      } else {
        document.getElementById('navbar_top').classList.remove('fixed-top');
         // remove padding top from body
        document.body.style.paddingTop = '0';
      } 

    });
  }); 
  
  (function() {
  
    function initOnLoad() {
      console.log('Dom loaded');
    };
    
    window.addEventListener('load', initOnLoad);
  })();

  document.querySelectorAll('.nav-link').forEach(item => {
    item.addEventListener('click', () => {
      document.querySelector('.navbar-collapse').classList.remove('show');
    });
  });

  return (
    <div>
      {/* <nav class="navbar navbar-expand-lg fixed-top navbar-light custom-navbar" id="navbar_top" style={{position: scrollingLock ? "fixed" : "fixed"}}>
        <div class="container-fluid">
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
               <a className={`nav-link ${activeLink === 'javascript:void(0)' ? 'active' : ''}`} href="javascript:void(0)" >Medium Article</a>
              </li>
              <li class="nav-item">
                <a className={`nav-link ${activeLink === 'javascript:void(0)' ? 'active' : ''}`} href="javascript:void(0)">Buy Now</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>  */}
     <div className=""></div>
        <div id="lore"> <Banner /> </div>       
       
    </div>
  );
}

export default Home;
