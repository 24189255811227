import React from "react";
import "../assets/css/Home.scss";
import logo from '../assets/image/logo/LOGO.png';
import twiter from '../assets/image/icons/Vector.png';
import medium from '../assets/image/icons/medium.png';
import raydium from '../assets/image/icons/raydium.png';
import pumpfun from '../assets/image/icons/pump_fun.png';



function Banner() {
  return (
    <>
  
      <div className="banner-wrapper">
      <div className="button-styles">   
      <div class="social">
          <a href="https://medium.com/@solaxyguardians/welcome-to-guardians-of-the-solaxy-c63cae8609bf" target='_blank'><img class="insta" src={medium} alt="" title='Medium'/></a>
          <a href="https://x.com/G_OF_SOLAXY" target='_blank'  title='Twitter'><img class="insta"src={twiter} alt=""/></a>
          <a href="javascript:void(0)"><img class="insta" src={raydium} alt="" title='raydium'/></a>
          <a href="https://pump.fun/coin/mPxdy2btphAnd1JxxCtSu5z1QytgVDVccg9uvfbpump"><img class="insta" src={pumpfun} alt="" title='pumpfun'/></a>
                  
        </div> 
    </div>
      <div className="banner">
      <section className="arrow-animate">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </section>
        <div className="container-fluid">
        

          <div className="row">
            <div className="col-lg-12">
          
              <div className="banner-content position-relative">
                  <div className="banner-owl-wrapper">
                    <div className="zoominheader">
                      <div className="zoomoutheader">
                      <div>
                      <img src={logo} className="img-fluid" />
                    </div>
                      </div>
                    </div>
                  
                  </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </>
  );
}

export default Banner;
